<template>
  <div class="login_content">
    <div class="box">
      <!-- 用户登录 -->
      <el-card class="box_card">
        <h1>登录</h1>
        <!-- 表单登录 -->
        <el-form ref="loginFormRef" @submit.native.prevent="login" :model="form" label-width="80px">
          <!-- 手机号 -->
          <el-form-item label="手机号:">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item label="密码:">
            <el-input type="passWord" v-model="form.password"></el-input>
          </el-form-item>
          <!-- 登录按钮 -->
          <div class="login_btn">
            <el-input type="submit" round>登录</el-input>
          </div>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        phone: '',
        password: ''
      }
    }
  },
  methods: {
    // 登录
    async login () {
      try {
        const { data: res } = await this.$http.post('login', this.form)
        if (res.msg !== 'success') return this.$message({ message: '登录失败', type: 'error' })
        this.$message({ message: '登录成功', type: 'success' })
        window.sessionStorage.setItem('token', res.data.token)
        this.$router.push('/home')
      } catch (err) {
        this.$message({ message: '登录失败', type: 'error' })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login_content{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #324057;
  .box{
    width: 30%;
    .box_card,.box_card_register{
      h1{
        text-align: center;
        font-size: 2rem;
        padding: 1rem 0;
      }
      .login_btn{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .el-button{
          width: 50%;
          border-radius: 25px;
          padding: 1rem 0;
          margin: 0.5rem 0;
        }
      }
    }
    .login_btn{
      .el-input{
        width: 50%;
        margin: 0 auto;
      }
    }
  }
}
</style>
